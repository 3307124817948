<template>
  <b-card-code>
    <div class="custom-search d-flex">
    <b-button variant="outline-primary" @click="showModal">
      {{ $t('Add New') }}
    </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('Search') }}</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="marquees"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          <template>
            <b-form-checkbox :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
          </template>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="outline-primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button variant="outline-danger" @click="remove(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
        hide-footer
        :title="model_mode==='add'?$t('Add New'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_or_update">
          <b-row>
            <b-col md="6">
              <b-card-text>
                <span>{{ $t('Title') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Title')"
                    rules="required"
                >
                  <b-form-input
                      v-model="title"
                      name="title"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Write title here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-card-text>
                <span>{{ $t('Link') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('link')"
                    rules=""
                >
                  <b-form-input
                      v-model="link"
                      name="link"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Write/past link here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Text Color') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('text color')"
                    rules="required"
                >
                  <select name="text_color" v-model="text_color" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(color,key) in colors" :value="color.code" :key="key" :style="'color:'+color.code">{{color.name}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Background Color') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('background color')"
                    rules="required"
                >
                  <select name="background_color" v-model="background_color" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(color,key) in colors" :value="color.code" :key="key" :style="'background:'+color.code">{{color.name}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button class="mt-2"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,BCardText,
  BModal, BRow, BCol,BFormCheckbox,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
//import {mapGetters} from "vuex";
export default {
  name:'Marquee',
  components: {
    BCardCode,BFormCheckbox,
    VueGoodTable,BCardText,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,
    BRow, BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      title: '',
      link: '',
      text_color: null,
      background_color: null,
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Link',
          field: 'link',
        },
        {
          label: 'Text Color',
          field: 'text_color',
        },
        {
          label: 'Background Color',
          field: 'text_color',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      colors:[
        {code:'#FF0000', name:'Red'},
        {code:'#00FFFF', name:'Cyan'},
        {code:'#00008B', name:'DarkBlue'},
        {code:'#ADD8E6', name:'LightBlue'},
        {code:'#800080', name:'Purple'},
        {code:'#FFFF00', name:'Yellow'},
        {code:'#00FF00', name:'Lime'},
        {code:'#FF00FF', name:'Magenta'},
        {code:'#FFC0CB', name:'Pink'},
        {code:'#FFFFFF', name:'White'},
        {code:'#C0C0C0', name:'Silver'},
        {code:'#808080', name:'Gray or Grey'},
        {code:'#000000', name:'Black'},
        {code:'#FFA500', name:'Orange'},
        {code:'#A52A2A', name:'Brown'},
        {code:'#800000', name:'Maroon'},
        {code:'#008000', name:'Green'},
        {code:'#808000', name:'Olive'},
        {code:'#7FFD4', name:'Aquamarine'},
      ],
    }
  },
  methods:{
    selectedRow(row){
      this.selected_row=row;
      this.title=row.title;
      this.link=row.link;
      this.text_color=row.text_color;
      this.background_color=row.background_color;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_or_update);
          if (this.model_mode ==='add'){
            apiCall.post('/marquee/information/store',data).then((response)=>{
              this.hideModal();
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_MARQUEE');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/marquee/information/update${this.selected_row.id}`,data).then((response)=>{
              this.hideModal();
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_MARQUEE');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    statusChange(id){
      apiCall.put(`/marquee/status/change${id}`).then((response)=>{
        if (response.data.status==='success'){
          this.$toaster.success(response.data.message);
          this.$store.dispatch('GET_ALL_MARQUEE');
        }else{
          this.$store.dispatch('GET_ALL_MARQUEE');
        }
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
      });
    },
    remove(id){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/marquee/information/remove${id}`).then((response)=>{
              this.$store.dispatch('GET_ALL_MARQUEE');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }
        })
    },
    showModal() {
      this.selected_row={};
      this.title='';
      this.link='';
      this.text_color=null;
      this.background_color=null;
      this.model_mode='add';
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    marquees(){
      return this.$store.getters.marquees;
    },
    //...mapGetters(['versions']),
  },
  created() {
    this.$store.dispatch('GET_ALL_MARQUEE');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>